import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import axios from "axios";
import { Button, notification, useCall } from "@epcnetwork/core-ui-kit";

import { RootState, setUser } from "store";
import { UserModel } from "models";
import { appEnvironment } from "config/environment.config";
import { generateMfaUrl } from "api";
import { QrModal } from "./qr-modal/qr-modal";

import { CheckCircle, CrossCircle } from "assets/images";

import styles from "./mfa.module.scss";

export const Mfa: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  const [authenticationUrl, setAuthenticationUrl] = useState<string>("");

  const refreshUserData = async (token: string) => {
    try {
      const { data } = await axios.get<UserModel>("/auth/me", {
        baseURL: appEnvironment.apiUrl,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data) {
        dispatch(setUser(data));
      }
    } catch (error) {
      console.error("Could not refresh user data");
    }
  };

  const enableMfa = useCall(generateMfaUrl);
  enableMfa.onCallSuccess((response) => {
    setAuthenticationUrl(response);
  });
  enableMfa.onCallError(() => {
    notification.error(
      "Enable multi-factor authentication error",
      "Could not enable multi-factor authentication",
    );
  });

  const isQrModalOpen = Boolean(authenticationUrl);
  const handleQrModalClose = () => setAuthenticationUrl("");

  const handleEnableClick = async () => {
    await enableMfa.submit();
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Multi-factor authentication</h1>
      <p className={styles.description}>
        Use an authenticator app to enable multi-factor authentication for your account (e.g. Google
        Authenticator).
      </p>

      {user?.isMfaEnabled && (
        <div>
          <div className={styles.enabled}>
            <CheckCircle />
            <div>
              <p>Multi-factor authentication is already enabled.</p>
            </div>
          </div>
        </div>
      )}

      {!user?.isMfaEnabled && (
        <div>
          <div className={styles.disabled}>
            <CrossCircle />
            <div>
              <p>Multi-factor authentication is not enabled.</p>

              <span>
                Click the button below to enable multi-factor authentication for your account.
              </span>
            </div>
          </div>
          <Button onClick={handleEnableClick}>
            <CheckCircle />
            Enable multi-factor authentication
          </Button>
        </div>
      )}

      {Boolean(authenticationUrl) && (
        <QrModal
          isOpen={isQrModalOpen}
          handleClose={handleQrModalClose}
          qr={authenticationUrl}
          onSuccess={refreshUserData}
        />
      )}
    </div>
  );
};
