import { array, boolean, number, object, SchemaOf, string } from "yup";

import { MARKETO_ENDPOINT_REGEX } from "constants/regex.constants";
import { requiredFieldText } from "constants/form.constants";
import { MarketoColumn, MarketoType, MarketoColumnAttribute } from "./marketo.types";

const marketoColumns: SchemaOf<MarketoColumn> = object({
  field: string().required("Field is required"),
  isRequired: boolean().required("This field is required"),
  column: string().when("isRequired", (value, schema) => {
    if (value === true) {
      return schema.required("Column is required");
    }

    return schema;
  }),
});

const marketoColumnsSchema: SchemaOf<MarketoColumnAttribute> = object({
  fileId: string().required("File ID is required"),
  columns: array().of(marketoColumns),
});

export const marketoSchema: SchemaOf<MarketoType> = object({
  endpoint: string()
    .matches(MARKETO_ENDPOINT_REGEX, "Endpoint must be in a XXX-XXX-XXX format")
    .required("Endpoint is required"),
  clientId: string().required(requiredFieldText),
  clientSecret: string().required(requiredFieldText),
  formId: number().required(requiredFieldText).min(1, requiredFieldText),
  files: array().of(marketoColumnsSchema),
});
