import { FC, useState } from "react";

import { SliderControllers } from "./slider-controllers/slider-controllers";
import { SliderContent } from "./slider-content/slider-content";

import styles from "./login-slider.module.css";

export type SlideType = {
  id: number;
  slideName: string;
  slideDescription: string;
};

export type SliderInfoType = Array<SlideType>;

const sliderInfo: SliderInfoType = [
  {
    id: 0,
    slideName: "Control sent emails in one place",
    slideDescription: "The system automatically tracks and counts emails that need to be sent...",
  },
  {
    id: 1,
    slideName: "Send millions emails everyday",
    slideDescription: "Some description",
  },
  {
    id: 2,
    slideName: "Convenient, fast, simple to use",
    slideDescription: "Some description",
  },
];

const LoginSlider: FC = () => {
  const [currentSlide, setCurrentSlide] = useState(sliderInfo[0]);
  const updateCurrentSlide = (newSlide: SlideType) => {
    setCurrentSlide(newSlide);
  };
  return (
    <div className={styles.loginSlider}>
      <SliderContent currentSlide={currentSlide} />
      <SliderControllers
        setCurrentSlide={updateCurrentSlide}
        currentSlide={currentSlide}
        sliderInfo={sliderInfo}
      />
    </div>
  );
};

export { LoginSlider };
