import { useDispatch } from "react-redux";

import { setToken, setMfaTemporaryToken } from "store";
import { REFRESH_TOKEN_STORAGE_FIELD, TOKEN_STORAGE_FIELD } from "constants/auth.constants";

export const useMfa = () => {
  const dispatch = useDispatch();

  const setTokens = (userToken: string, userRefreshToken: string) => {
    dispatch(setToken(userToken));
    localStorage.setItem(TOKEN_STORAGE_FIELD, userToken);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, userRefreshToken);
  };

  const startMfa = (token: string) => {
    dispatch(setMfaTemporaryToken(token));
  };

  return { setTokens, startMfa };
};
