import { FC, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import { Table, useFetch } from "@epcnetwork/core-ui-kit";

import { setListElement } from "utils";
import { ColumnsType } from "types";
import { ArrayElement, UserModel } from "models";
import { getUsers } from "api";
import { UsersListTableRow } from "./users-list-table-row/users-list-table-row";
import { UpdateUser } from "./update/update-user";

type ListUsersProps = {
  searchValue: string;
};

const tableColumns: ColumnsType = [
  { label: "Email", required: true },
  { label: "Roles" },
  { label: "Group" },
  { label: "Created at" },
  { label: "" },
];

export const ListUsers: FC<ListUsersProps> = ({ searchValue }) => {
  const [itemToEdit, setItemToEdit] = useState<UserModel | null>(null);

  const { payload, loading, refresh, error, actions } = useFetch(getUsers);

  const handleEdit = (item: UserModel) => {
    setItemToEdit(item);
  };

  const onModifySuccess = (values: UserModel) => {
    const list = payload || [];
    const newData = setListElement(list, values);

    actions.setData(newData);
  };

  const filteredList = useMemo(() => {
    let list: NonNullable<typeof payload> = payload || [];

    if (searchValue) {
      const keys: (keyof ArrayElement<NonNullable<typeof payload>>)[] = ["email"];
      list = matchSorter(list, searchValue, { keys });
    }

    return list;
  }, [searchValue, payload]);

  return (
    <>
      <Table
        entityName="users-user-list"
        columns={tableColumns}
        list={filteredList}
        error={error?.message}
        refresh={refresh}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        row={(item, index) => (
          <UsersListTableRow index={index} item={item} handleEdit={handleEdit} />
        )}
      />

      {itemToEdit && (
        <UpdateUser
          item={itemToEdit}
          isOpen={!!itemToEdit}
          handleClose={() => setItemToEdit(null)}
          onUpdateSuccess={onModifySuccess}
        />
      )}
    </>
  );
};
