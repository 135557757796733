import QRCode from "react-qr-code";
import React from "react";
import { Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { useMfa } from "hooks";
import { OtpInput } from "components";
import { enableMfa } from "api";
import { QrModalProps } from "./qr-modal.types";

import styles from "./qr-modal.module.scss";

export const QrModal: React.FC<QrModalProps> = ({ isOpen, handleClose, qr, onSuccess }) => {
  const { setTokens } = useMfa();

  const { submit, onCallSuccess, onCallError, submitting, error } = useCall(enableMfa);
  onCallSuccess((response) => {
    notification.success(
      "Multi-factor authentication enabled",
      "You enabled virtual MFA for your account.",
    );
    onSuccess?.(response.token);
    handleClose();
    setTokens(response.token, response.refreshToken);
  });
  onCallError(() => {
    notification.error(
      "Multi-factor authentication error",
      "Could not enable virtual MFA for your account.",
    );
  });

  const handleSubmit = async (value: string) => {
    await submit({ data: { code: value } });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose} size="small">
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          Scan this QR code in the authenticator app
          <br />
          (Google Authenticator or Microsoft Authenticator)
        </h4>
        <QRCode value={qr} className={styles.qrCode} />
        <div className={styles.qrLink}>
          <p>
            Click
            <a href={qr} className={styles.link}>
              here
            </a>
            to start using the Authenticator app
          </p>
        </div>

        <div className={styles.qrForm}>
          <p className={styles.qrCodeLabel}>Enter code from the authenticator app</p>
          <div>
            <OtpInput onSubmit={handleSubmit} submitting={submitting} error={error?.message} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
