import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { FC, ReactNode } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  ThemeProvider,
  FileQueueProvider,
  NotificationProvider,
  ErrorBoundary,
  useDidMount,
} from "@epcnetwork/core-ui-kit";

import store from "store";
import { useTracking } from "hooks";
import { lightTheme } from "constants/themes.constants";
import { DEFAULT_PAGE } from "constants/routes.constants";
import { appEnvironment } from "config/environment.config";
import { AuthorizationGuard } from "../authorization";

const themes = [lightTheme];

interface ProvidersProps {
  children: ReactNode;
}

const Providers: FC<ProvidersProps> = ({ children }) => {
  const { init } = useTracking();

  useDidMount(() => {
    init();
  });

  const handleRefresh = () => (window.location.href = DEFAULT_PAGE.path);

  return (
    <Provider store={store}>
      <AuthorizationGuard>
        <BrowserRouter>
          <ErrorBoundary
            title="Oops! Something went wrong"
            subtitle="Page could not be loaded. Please try later"
            refreshText="Go to homepage"
            refreshClick={handleRefresh}
          >
            <ThemeProvider themes={themes} initialTheme={themes[0]}>
              <GoogleOAuthProvider clientId={appEnvironment.googleClientId}>
                <FileQueueProvider>
                  <NotificationProvider>{children}</NotificationProvider>
                </FileQueueProvider>
              </GoogleOAuthProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </AuthorizationGuard>
    </Provider>
  );
};

export { Providers };
