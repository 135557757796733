import { object, string, number, array, AnyObjectSchema } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { WhitelistFormData } from "api/users/users.interface";

export const validationSchema: AnyObjectSchema = object().shape({
  email: string().email("Email is not valid").required("Email is required"),
  roleId: number().required("Role is required").typeError("Role is invalid"),
  groupIds: array(number().required(requiredFieldText))
    .min(1, requiredFieldText)
    .typeError(requiredFieldText),
});

export const initialData: WhitelistFormData = {
  email: "",
  roleId: null,
  groupIds: [],
};
