import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
  useDidUpdate,
  Tooltip,
  Label,
} from "@epcnetwork/core-ui-kit";

import { DataExtensionField, SalesforceIntegration, SelectOption } from "models";
import { TabOption, Tabs } from "components";
import { getDataExtensionFields, getDataExtensions, getSalesforceLists } from "api";
import { useConfigs } from "../hooks/use-configs/use-configs.hook";
import { InitialValues } from "../../jobs-form.types";
import { SalesforceProps, DataExtensionType, SalesforceType } from "./salesforce.types";
import { ListsColumns } from "./lists-columns/lists-columns";
import { getInitialDataExtensionColumns } from "./data-extension-columns/data-extension-columns.constants";
import { DataExtensionColumns } from "./data-extension-columns/data-extension-columns";

import { Download } from "assets/images";
import mainStyles from "../connectors.module.css";
import styles from "../../job-form.module.css";

const Salesforce: FC<SalesforceProps> = ({ endpointOrdinal, isInReconfiguration }) => {
  const { values, errors, setFieldValue, setFieldTouched, submitCount } =
    useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig, selectedConfig, setSelectedConfig } =
    useConfigs<SalesforceIntegration>("salesforce");

  const [dataExtensionFields, setDataExtensionFields] = useState<DataExtensionField[]>([]);
  const [dataExtensionOptions, setDataExtensionOptions] = useState<DataExtensionType[]>([]);
  const [salesforceLists, setSalesforceLists] = useState<DataExtensionType[]>([]);

  const salesforceValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | SalesforceType
    | undefined;

  const { clientId, clientSecret, origin, authOrigin, soapOrigin, listId, type } =
    salesforceValues || {};

  const getDataExtensionFieldsCall = useCall(getDataExtensionFields);
  getDataExtensionFieldsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    setDataExtensionFields(payload);

    if (!isInReconfiguration) {
      // if is reconfiguration mode we don't want to set initial values
      setFieldValue(
        `endpoints[${endpointOrdinal}].connector.properties.custom_attributes`,
        getInitialDataExtensionColumns(values.attachedFiles, payload),
      );
    }
  });
  getDataExtensionFieldsCall.onCallError(() => {
    notification.error("Fetching error", "Could not fetch data extension fields, please try again");
  });

  const getDataExtensionsCall = useCall(getDataExtensions);
  getDataExtensionsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: DataExtensionType[] = payload.map(({ listId, name }) => ({
      value: listId ?? "",
      label: name,
    }));

    notification.success("Data extensions fetched!", "Select your data extension.");
    setDataExtensionOptions(optionsToSave);
  });
  getDataExtensionsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const getSalesforceListsCall = useCall(getSalesforceLists);
  getSalesforceListsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning(
        "No Salesforce lists",
        "Please check Salesforce credentials and try again",
      );

    const optionsToSave: DataExtensionType[] = payload.map(({ listId, name }) => ({
      value: listId ?? "",
      label: name,
    }));

    notification.success("Salesforce lists fetched!", "Select your Salesforce list.");
    setSalesforceLists(optionsToSave);
  });
  getSalesforceListsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const isListDisabled =
    !clientId ||
    !clientSecret ||
    !origin ||
    !authOrigin ||
    !soapOrigin ||
    getDataExtensionsCall.submitting ||
    getSalesforceListsCall.submitting;

  const handleGetDataExtensionsClick = () => {
    setDataExtensionOptions([]);

    getDataExtensionsCall.submit({
      data: {
        clientId: clientId?.trim(),
        clientSecret: clientSecret?.trim(),
        origin: origin?.trim(),
        authOrigin: authOrigin?.trim(),
        soapOrigin: soapOrigin?.trim(),
      },
    });
  };

  const handleGetSalesforceListsClick = () => {
    setSalesforceLists([]);

    getSalesforceListsCall.submit({
      data: {
        clientId: clientId?.trim(),
        clientSecret: clientSecret?.trim(),
        origin: origin?.trim(),
        authOrigin: authOrigin?.trim(),
        soapOrigin: soapOrigin?.trim(),
      },
    });
  };

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    setSelectedConfig(value);
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.clientId`, config.properties.clientId);
      await setFieldValue(`${propertiesField}.clientSecret`, config.properties.clientSecret);
      await setFieldValue(`${propertiesField}.origin`, config.properties.origin);
      await setFieldValue(`${propertiesField}.authOrigin`, config.properties.authOrigin);
      await setFieldValue(`${propertiesField}.soapOrigin`, config.properties.soapOrigin);
    }
  };

  const handleTypeChange = ({ value }: TabOption<string>) => {
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.type`, value);
    // sync validation
    setTimeout(() =>
      setFieldTouched(`endpoints[${endpointOrdinal}].connector.properties.type`, true),
    );
  };

  const handleListSelect = (option: SelectOption<string>) => {
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.listId`, option?.value || "");
    // sync validation
    setTimeout(() =>
      setFieldTouched(`endpoints[${endpointOrdinal}].connector.properties.listId`, true),
    );

    if (selectedType === "dataExtension" && option) {
      getDataExtensionFieldsCall.submit({
        data: {
          clientId: clientId?.trim(),
          clientSecret: clientSecret?.trim(),
          origin: origin?.trim(),
          authOrigin: authOrigin?.trim(),
          soapOrigin: soapOrigin?.trim(),
          listId: option.value,
        },
      });
    }
  };

  useDidUpdate(() => {
    if (options.length === 1) {
      handleConfigChange(options[0]);
    }
  }, [options]);

  useDidMount(() => {
    // fetch lists or data extensions
    if (!isListDisabled && listId) {
      if (type === "dataExtension") {
        handleGetDataExtensionsClick();
        getDataExtensionFieldsCall.submit({
          data: {
            clientId: clientId?.trim(),
            clientSecret: clientSecret?.trim(),
            origin: origin?.trim(),
            authOrigin: authOrigin?.trim(),
            soapOrigin: soapOrigin?.trim(),
            listId,
          },
        });
      } else {
        handleGetSalesforceListsClick();
      }
    }
  });

  useDidUpdate(() => {
    // reset listId and custom attributes on type change
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.listId`, "");
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`, []);
  }, [type]);

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  const selectedType =
    // @ts-ignore
    values.endpoints[`${endpointOrdinal}`].connector.properties?.type;

  return (
    <>
      <div className={mainStyles.configGrid}>
        <Label text="Load Salesforce config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          selectedOptionsKeys={selectedConfig}
          isSearchable
          inputSize="small"
          disableClearing
          inputClassName={mainStyles.configSelect}
          placeholder="Select Salesforce config"
          searchPlaceholder="Search config"
          disableError
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientId`}
        label="Client ID"
        placeholder="Client ID"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientSecret`}
        label="Client Secret"
        placeholder="Client Secret"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.origin`}
        label="Origin"
        placeholder="Origin"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.authOrigin`}
        label="Auth Origin"
        placeholder="Auth Origin"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.soapOrigin`}
        label="Soap Origin"
        placeholder="Soap Origin"
      />

      <Tabs
        selected={selectedType}
        onChange={handleTypeChange}
        options={[
          { value: "dataExtension", label: "Data extension" },
          { value: "list", label: "List" },
        ]}
      />

      <div className={styles.listWrapper}>
        <Select
          label={selectedType === "list" ? "List" : "Data extension"}
          placeholder={selectedType === "list" ? "Select Salesforce list" : "Select data extension"}
          searchPlaceholder={selectedType === "list" ? "Search list" : "Search data extension"}
          name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
          error={errorText}
          selectedOptionsKeys={listId}
          options={selectedType === "list" ? salesforceLists : dataExtensionOptions}
          onChange={handleListSelect}
          disabled={isListDisabled}
          isSearchable
          asyncOptions={{
            loading:
              selectedType === "list"
                ? getSalesforceListsCall.submitting
                : getDataExtensionsCall.submitting,
          }}
        />
        <Tooltip
          trigger="hover"
          triggerElement={
            <Button
              disabled={isListDisabled}
              onClick={
                selectedType === "list"
                  ? handleGetSalesforceListsClick
                  : handleGetDataExtensionsClick
              }
              className={styles.btnDataExtensions}
            >
              <Download />
            </Button>
          }
        >
          {selectedType === "list" ? "Get Salesforce lists" : "Get data extensions"}
        </Tooltip>
      </div>

      {selectedType === "list" && listId && <ListsColumns endpointOrdinal={endpointOrdinal} />}

      {selectedType === "dataExtension" && listId && dataExtensionFields.length > 0 && (
        <DataExtensionColumns
          endpointOrdinal={endpointOrdinal}
          fields={dataExtensionFields}
          fieldsLoading={getDataExtensionFieldsCall.submitting}
        />
      )}
    </>
  );
};

export { Salesforce };
