import { object, string, SchemaOf } from "yup";
import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
  Tooltip,
  Label,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { GetResponseIntegration, SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { getGetResponseCampaigns } from "api";
import { useConfigs } from "../hooks/use-configs/use-configs.hook";
import { InitialValues } from "../../jobs-form.types";

import { Download } from "assets/images";
import mainStyles from "../connectors.module.css";
import styles from "../../job-form.module.css";

export const getResponseSchema: SchemaOf<GetResponseType> = object({
  apiKey: string().required(requiredFieldText),
  campaignId: string().required(requiredFieldText),
});

type GetResponseListType = SelectOption<string>;

export type GetResponseType = {
  apiKey: string;
  campaignId: string;
};

type GetResponseProps = {
  endpointOrdinal: number;
};

export const GetResponse: FC<GetResponseProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, submitCount } = useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig, selectedConfig, setSelectedConfig } =
    useConfigs<GetResponseIntegration>("getResponse");

  const [getResponseCampaigns, setGetResponseCampaigns] = useState<GetResponseListType[]>([]);

  const getResponseValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | GetResponseType
    | undefined;

  const { apiKey, campaignId } = getResponseValues || {};

  const { submit, onCallSuccess, onCallError, submitting } = useCall(getGetResponseCampaigns);
  onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: GetResponseListType[] = payload.map(({ campaignId, name }) => ({
      value: campaignId ?? "",
      label: name,
    }));

    notification.success("GetResponse campaigns fetched!", "Select your GetResponse campaign.");
    setGetResponseCampaigns(optionsToSave);
  });
  onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const isListDisabled = !apiKey || submitting;

  useDidMount(() => {
    if (!isListDisabled) {
      handleGetGetResponseCampaigns();
    }
  });

  const handleGetGetResponseCampaigns = () => {
    setGetResponseCampaigns([]);

    submit({
      data: {
        apiKey: apiKey?.trim(),
      },
    });
  };

  const handleCampaignSelect = async (option: SelectOption<string>) => {
    const { value } = option || {};
    await setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.campaignId`,
      value || "",
    );
  };

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    setSelectedConfig(value);
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.apiKey`, config.properties.apiKey);
    }
  };

  useDidUpdate(() => {
    if (options.length === 1) {
      handleConfigChange(options[0]);
    }
  }, [options]);

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  return (
    <>
      <div className={mainStyles.configGrid}>
        <Label text="Load GetResponse config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          selectedOptionsKeys={selectedConfig}
          isSearchable
          searchPlaceholder="Search config"
          inputSize="small"
          inputClassName={mainStyles.configSelect}
          placeholder="Select GetResponse config"
          disableError
          disableClearing
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="API Key"
        placeholder="GetResponse API Key"
      />

      <div className={styles.listWrapper}>
        <Select
          label="Campaign"
          placeholder="Select GetResponse campaign"
          name={`endpoints[${endpointOrdinal}].connector.properties.campaignId`}
          error={errorText}
          searchPlaceholder="Search campaign"
          selectedOptionsKeys={campaignId}
          options={getResponseCampaigns}
          onChange={handleCampaignSelect}
          disabled={isListDisabled}
          isSearchable
        />

        <Tooltip
          trigger="hover"
          triggerElement={
            <Button
              disabled={isListDisabled}
              onClick={handleGetGetResponseCampaigns}
              className={styles.btnDataExtensions}
            >
              <Download />
            </Button>
          }
        >
          Get GetResponse campaigns
        </Tooltip>
      </div>
    </>
  );
};
