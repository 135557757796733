import { UserModel, WhitelistUserModel } from "models";
import { apiMiddleware } from "api";
import { UserFormData, WhitelistFormData } from "./users.interface";

export const getUsers = apiMiddleware<UserModel[]>()({
  method: "get",
  endpoint: `/users`,
});

export const getWhitelistUsers = apiMiddleware<WhitelistUserModel[]>()({
  method: "get",
  endpoint: `/users/white-list`,
});

export const getUser = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: `/users/:userId`,
});

export const postUser = apiMiddleware<UserModel, UserFormData>()({
  method: "post",
  endpoint: "/users",
});

export const createWhitelistUser = apiMiddleware<WhitelistUserModel, WhitelistFormData>()({
  method: "post",
  endpoint: "/users/white-list",
});

export const putUser = apiMiddleware<UserModel, UserFormData>()({
  method: "put",
  endpoint: `/users/:userId`,
});

export const updateWhitelistUser = apiMiddleware<WhitelistUserModel, WhitelistFormData>()({
  method: "put",
  endpoint: "/users/white-list/:userId",
});

export const deleteUser = apiMiddleware<UserModel>()({
  method: "delete",
  endpoint: `/users/:userId`,
});
