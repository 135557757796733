import { useSelector } from "react-redux";
import { FC } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  useAutocomplete,
  Modal,
  useCall,
  notification,
} from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { WhitelistUserModel } from "models";
import { CANCEL_TEXT } from "constants/notification.constants";
import { WhitelistFormData } from "api/users/users.interface";
import { createWhitelistUser, getRoles, getGroups } from "api";
import { validationSchema, initialData } from "./create-whitelist-user.constants";

import styles from "./create-whitelist-user.module.css";

type CreateWhitelistUserProps = {
  isOpen: boolean;
  handleClose: () => void;
  onCreateSuccess: (whitelistUser: WhitelistUserModel) => void;
};

export const CreateWhitelistUser: FC<CreateWhitelistUserProps> = ({
  isOpen,
  handleClose,
  onCreateSuccess,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { submit, onCallSuccess, onCallError, error } = useCall(createWhitelistUser);
  onCallSuccess((payload) => {
    onCreateSuccess(payload);
    handleClose();
    notification.success("Create success", "Whitelist user has been created!");
  });
  onCallError((error) => {
    notification.error("Create error", "Could not create a whitelist user");
  });

  const rolesAutocomplete = useAutocomplete(getRoles, "id", "name");
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");

  const handleSubmit = async (values: WhitelistFormData) => {
    await submit({ data: values });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <div className={styles.title}>Create Whitelist User</div>
          {!!error && <MessageField message={error?.message} />}

          <div className={styles.row}>
            <FormField
              type="text"
              name="email"
              label="Email"
              placeholder="name@email.com"
              required
              autoComplete="off"
            />
            <FormField
              type="select"
              name="roleId"
              label="Roles"
              placeholder="Roles"
              asyncOptions={rolesAutocomplete}
              required
            />
            <FormField
              type="select"
              name="groupIds"
              label="Groups"
              placeholder="Groups"
              asyncOptions={groupsAutocomplete}
              isMulti
              required
            />
          </div>
          <FormButtons>
            <Button appearance="secondary" onClick={handleClose}>
              {CANCEL_TEXT}
            </Button>
            <Button type="submit">Submit</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
