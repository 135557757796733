import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { memo, useMemo } from "react";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";

import { getActiveRoute } from "utils";
import { RootState } from "store";
import { PageNameTooltip } from "./components/page-name-tooltip";
import { Menu } from "./components/menu";

import openedImg from "assets/images/x-cross-rounded-grey.svg";
import userImg from "assets/images/user.svg";
import closedImg from "assets/images/hamburger.svg";
import styles from "./navbar.module.css";

type NavbarProps = {
  isMenuClosed: boolean;
  toggleSidebar: VoidFunction;
};

const Navbar = memo<NavbarProps>(({ toggleSidebar, isMenuClosed }) => {
  const location = useLocation();

  const { user } = useSelector((state: RootState) => state.auth);

  const activeRoute = useMemo(() => getActiveRoute(location), [location]);

  const menuIcon = isMenuClosed ? closedImg : openedImg;

  return (
    <header className={styles.container} data-testid="navbar">
      <Button className={styles.hamburger} appearance="secondary" onClick={toggleSidebar} imageBtn>
        <img src={menuIcon} alt="" />
      </Button>
      <div className={styles.title}>
        {activeRoute.name}
        <PageNameTooltip tooltipText={activeRoute.pageNameTooltipText} />
      </div>
      <div className={styles.spacer} />
      <Tooltip
        triggerElement={
          <Button appearance="none" imageBtn>
            <img src={user?.pictureUrl || userImg} alt="" className={styles.avatarImage} />
          </Button>
        }
        position="bottom-right"
        triggerClassName={styles.menuTrigger}
      >
        <Menu />
      </Tooltip>
    </header>
  );
});

export { Navbar };
