import { useCallback, useMemo, useState } from "react";
import { Nullable, useFetch } from "@epcnetwork/core-ui-kit";

import { EndpointAPITypesKeys } from "models";
import { getIntegrations, IntegrationsResponse } from "api";

export const useConfigs = <T extends IntegrationsResponse>(apiType: EndpointAPITypesKeys) => {
  const { payload, loading } = useFetch(getIntegrations.setQueryParams(`?apiType=${apiType}`));

  const [selectedConfig, setSelectedConfig] = useState<number>(0);

  const options = useMemo(() => {
    if (!payload || !payload?.data) return [];
    return payload.data.map((integration) => ({
      label: integration.name,
      value: integration.id,
    }));
  }, [payload]);

  const getSelectedConfig = useCallback(
    (value: number) => {
      return payload?.data?.find((integration) => integration.id === value) as Nullable<T>;
    },
    [payload],
  );

  return { options, loading, getSelectedConfig, selectedConfig, setSelectedConfig };
};
