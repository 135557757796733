import { MultiFactorAuthData } from "api/mfa/mfa.types";
import { TokensResponse } from "api/auth/auth.interface";
import { apiMiddleware } from "api/api.middleware";

export const generateMfaUrl = apiMiddleware<string>()({
  method: "get",
  endpoint: "/auth/generate-otp-url",
});

export const enableMfa = apiMiddleware<TokensResponse, MultiFactorAuthData>()({
  method: "post",
  endpoint: "/auth/otp-enable",
});

export const authenticateMfa = (temporaryToken: string) =>
  apiMiddleware<TokensResponse, MultiFactorAuthData>()({
    endpoint: "/auth/otp-auth",
    method: "post",
    headers: {
      Authorization: `Bearer ${temporaryToken}`,
    },
  });
