import { useDispatch } from "react-redux";
import React from "react";
import { Button, notification, useCall } from "@epcnetwork/core-ui-kit";

import { setMfaTemporaryToken } from "store";
import { useMfa } from "hooks";
import { OtpInput } from "components";
import { authenticateMfa } from "api";

import { ArrowLeft } from "assets/images";

import styles from "./mfa-form.module.scss";

interface Props {
  temporaryToken: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const MfaForm: React.FC<Props> = ({ temporaryToken, onSuccess, onError }) => {
  const dispatch = useDispatch();

  const { setTokens } = useMfa();

  const { submit, submitting, onCallSuccess, onCallError, error } = useCall(
    authenticateMfa(temporaryToken),
  );
  onCallSuccess((response) => {
    setTokens(response.token, response.refreshToken);
    onSuccess?.();
  });
  onCallError((response) => {
    notification.error(
      "Incorrect multi-factor authentication code",
      "Please provide a correct multi-factor authentication code",
    );
  });

  const handleSubmit = async (value: string) => {
    await submit({ data: { code: value } });
  };

  const handleGoBackClick = () => {
    dispatch(setMfaTemporaryToken(null));
  };

  return (
    <div className={styles.virtualMfaForm}>
      <p className={styles.caption}>
        Enter code from the authentication app
        <br />
        (Google Authenticator or Microsoft Authenticator)
      </p>
      <OtpInput onSubmit={handleSubmit} submitting={submitting} error={error?.message} />

      {!submitting && (
        <Button appearance="text" className={styles.goBack} onClick={handleGoBackClick}>
          <ArrowLeft />
          Go back
        </Button>
      )}
    </div>
  );
};
