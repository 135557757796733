import { object, SchemaOf, string } from "yup";
import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { MARKETO_ENDPOINT_REGEX } from "constants/regex.constants";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { requiredFieldText, searchMinLength } from "constants/form.constants";
import { IntegrationData, MarketoData } from "api";
import { IntegrationsQuery } from "../integrations-list.types";

export const initialFilters: UseFiltersProps<IntegrationsQuery> = {
  searchOptions: { searchKey: "search", searchMinLength },
  initialState: { search: "", limit: defaultLimit, offset: defaultOffset, apiType: "marketo" },
};

export const columns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "Client ID" },
  { label: "Client secret" },
  { label: "Endpoint" },
  { label: "" },
];

// @ts-ignore
export const marketoSchema: SchemaOf<IntegrationData<MarketoData>> = object({
  apiType: string().required(requiredFieldText),
  name: string().required(requiredFieldText),
  properties: object().shape({
    endpoint: string()
      .matches(MARKETO_ENDPOINT_REGEX, "Endpoint must be in a XXX-XXX-XXX format")
      .required("Endpoint is required"),
    clientId: string().required(requiredFieldText),
    clientSecret: string().required(requiredFieldText),
  }),
});
