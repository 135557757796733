import { useState, FC } from "react";
import { Button, Search, Tabs, useFilters, useWindowSize } from "@epcnetwork/core-ui-kit";

import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { searchMinLength } from "constants/form.constants";
import { Container } from "components";
import { Whitelist } from "./whitelist/whitelist";
import { ListUsers } from "./list-users/list-users";
import { ListRoles } from "./list-roles/list-roles";
import { ListGroups } from "./list-groups/list-groups";

import styles from "./users-list.module.css";

type FilterQueryParams = {
  tab?: number;
};

const filtersOptions = {
  searchOptions: { searchKey: "name", searchMinLength },
};

const UsersListPage: FC = () => {
  const ability = useAuthorization();

  const { width } = useWindowSize();
  const { searchValue, setSearch } = useFilters<FilterQueryParams>(filtersOptions);

  const [activeTab, setActiveTab] = useState(0);
  const [isAddingGroup, setAddingGroup] = useState(false);
  const [createWhitelistItemOpen, setCreateWhitelistItemOpen] = useState<boolean>(false);

  const onTabClick = (index: number) => {
    setSearch("");
    setActiveTab(index);
  };

  const tabs = [
    {
      tab: {
        name: "Users",
      },
      tabComponent: <ListUsers searchValue={searchValue} />,
    },
    {
      tab: {
        name: "Whitelist",
      },
      tabComponent: (
        <Whitelist
          searchValue={searchValue}
          setCreateOpen={setCreateWhitelistItemOpen}
          createOpen={createWhitelistItemOpen}
        />
      ),
    },
    {
      tab: {
        name: "Roles",
      },
      tabComponent: <ListRoles searchValue={searchValue} />,
    },
    {
      tab: {
        name: "Groups",
      },
      tabComponent: (
        <ListGroups searchValue={searchValue} setAdding={setAddingGroup} isAdding={isAddingGroup} />
      ),
    },
  ];

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
          {!isMobile && activeTab === 1 && ability.can("create", "Users") && (
            <Button onClick={() => setCreateWhitelistItemOpen(true)}>+ Add Whitelist User</Button>
          )}
          {!isMobile && activeTab === 3 && ability.can("create", "Users") && (
            <Button onClick={() => setAddingGroup(true)}>+ Add Group</Button>
          )}
        </div>
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      </div>
    </Container>
  );
};

export { UsersListPage };
