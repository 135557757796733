import { object, string, SchemaOf, number } from "yup";
import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
  Tooltip,
  Label,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { GreenArrowIntegration, SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { getGreenArrowLists } from "api";
import { InitialValues } from "../jobs-form.types";
import { useConfigs } from "./hooks/use-configs/use-configs.hook";

import { Download } from "assets/images";
import styles from "../job-form.module.css";
import mainStyles from "./connectors.module.css";

export const greenArrowSchema: SchemaOf<GreenArrowType> = object({
  apiKey: string().required(requiredFieldText),
  apiUrl: string().url("URL is invalid").required(requiredFieldText),
  listId: number().required(requiredFieldText).min(1, requiredFieldText),
});

type GreenArrowListType = SelectOption<string>;

export type GreenArrowType = {
  apiKey: string;
  apiUrl: string;
  listId: number;
};

type GreenArrowProps = {
  endpointOrdinal: number;
};

const GreenArrow: FC<GreenArrowProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, submitCount } = useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig, selectedConfig, setSelectedConfig } =
    useConfigs<GreenArrowIntegration>("greenarrow");

  const [greenArrowLists, setGreenArrowLists] = useState<GreenArrowListType[]>([]);

  const greenArrowValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | GreenArrowType
    | undefined;

  const { apiKey, apiUrl, listId } = greenArrowValues || {};

  const getGreenArrowListsCall = useCall(getGreenArrowLists);
  getGreenArrowListsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: GreenArrowListType[] = payload.map(({ id, name }) => ({
      value: id ?? "",
      label: name,
    }));

    notification.success("GreenArrow lists fetched!", "Select your GreenArrow list.");
    setGreenArrowLists(optionsToSave);
  });
  getGreenArrowListsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const isListDisabled = !apiKey || !apiUrl || getGreenArrowListsCall.submitting;

  useDidMount(() => {
    if (!isListDisabled) {
      handleGetGreenArrowLists();
    }
  });

  const handleGetGreenArrowLists = () => {
    setGreenArrowLists([]);

    getGreenArrowListsCall.submit({
      data: {
        apiKey: apiKey?.trim(),
        apiUrl: apiUrl?.trim(),
      },
    });
  };

  const handleListSelect = (option: SelectOption<string>) => {
    const { value } = option || {};
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.listId`, value || "");
  };

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    setSelectedConfig(value);
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.apiKey`, config.properties.apiKey);
      await setFieldValue(`${propertiesField}.apiUrl`, config.properties.apiUrl);
    }
  };

  useDidUpdate(() => {
    if (options.length === 1) {
      handleConfigChange(options[0]);
    }
  }, [options]);

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  return (
    <>
      <div className={mainStyles.configGrid}>
        <Label text="Load GreenArrow config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          selectedOptionsKeys={selectedConfig}
          isSearchable
          disableClearing
          inputSize="small"
          inputClassName={mainStyles.configSelect}
          placeholder="Select GreenArrow config"
          searchPlaceholder="Search config"
          disableError
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="API Key"
        placeholder="GreenArrow API Key"
      />

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiUrl`}
        label="API URL"
        placeholder="GreenArrow API URL"
      />

      <div className={styles.listWrapper}>
        <Select
          label="List"
          placeholder="Select GreenArrow list"
          searchPlaceholder="Search list"
          name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
          error={errorText}
          selectedOptionsKeys={listId}
          options={greenArrowLists}
          onChange={handleListSelect}
          disabled={isListDisabled}
          isSearchable
        />

        <Tooltip
          trigger="hover"
          triggerElement={
            <Button
              disabled={isListDisabled}
              onClick={handleGetGreenArrowLists}
              className={styles.btnDataExtensions}
            >
              <Download />
            </Button>
          }
        >
          Get GreenArrow lists
        </Tooltip>
      </div>
    </>
  );
};

export { GreenArrow };
