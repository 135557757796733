import { object, SchemaOf, string } from "yup";
import { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, Label, Select, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { RemarketyIntegration, SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { useConfigs } from "./hooks/use-configs/use-configs.hook";

import mainStyles from "./connectors.module.css";

export const remarketySchema: SchemaOf<RemarketyType> = object({
  storeId: string().required(requiredFieldText),
  apiKey: string().required(requiredFieldText),
});

export type RemarketyType = {
  storeId: string;
  apiKey: string;
};

type RemarketyProps = {
  endpointOrdinal: number;
};

const Remarkety: FC<RemarketyProps> = ({ endpointOrdinal }) => {
  const { setFieldValue } = useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig, selectedConfig, setSelectedConfig } =
    useConfigs<RemarketyIntegration>("remarkety");

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    setSelectedConfig(value);
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.apiKey`, config.properties.apiKey);
    }
  };

  useDidUpdate(() => {
    if (options.length === 1) {
      handleConfigChange(options[0]);
    }
  }, [options]);

  return (
    <>
      <div className={mainStyles.configGrid}>
        <Label text="Load Remarkety config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          selectedOptionsKeys={selectedConfig}
          isSearchable
          disableClearing
          searchPlaceholder="Search config"
          inputSize="small"
          inputClassName={mainStyles.configSelect}
          placeholder="Select Remarkety config"
          disableError
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.storeId`}
        label="Store ID"
        placeholder="Store ID"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="Api Key"
        placeholder="Api Key"
      />
    </>
  );
};

export { Remarkety };
