import React, { useState } from "react";
import { CredentialResponse, GoogleLogin as GoogleLoginComponent } from "@react-oauth/google";
import { Button, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { useMfa } from "hooks";
import { appEnvironment } from "config/environment.config";
import { Loader } from "components";
import { devLogin, login } from "api";

import { CrossCircle } from "assets/images";
import styles from "./google.module.css";

export const GoogleLogin: React.FC = () => {
  const { startMfa, setTokens } = useMfa();

  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const { submit, submitting, onCallSuccess, onCallError } = useCall(login);
  onCallSuccess(async (response) => {
    if (response.isMfaEnabled) startMfa(response.token);
    if (!response.isMfaEnabled) setTokens(response.token, response.refreshToken);
  });
  onCallError(() => {
    setErrorOpen(true);
  });

  const devSignIn = useCall(devLogin);
  devSignIn.onCallSuccess(async (response) => {
    if (response.isMfaEnabled) startMfa(response.token);
    if (!response.isMfaEnabled) setTokens(response.token, response.refreshToken);
  });
  devSignIn.onCallError((response) => {
    notification.error("Dev sign in error", response?.message || "Error while trying to sign in.");
  });

  const executeGoogleLogin = async (credentialResponse: CredentialResponse) => {
    if (credentialResponse && credentialResponse?.credential) {
      await submit({
        data: { idToken: credentialResponse.credential },
      });
    }
  };

  const handleDevLogin = async () => {
    if (appEnvironment.nodeEnv) {
      await devSignIn.submit({ data: { email: appEnvironment.devSignInEmail } });
    }
  };

  return (
    <div>
      <div
        className={styles.buttons}
        style={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        {appEnvironment.nodeEnv === "development" && (
          <Button onClick={handleDevLogin} style={{ marginLeft: 0, width: "100%" }}>
            Dev sign in
          </Button>
        )}
        <GoogleLoginComponent onSuccess={executeGoogleLogin} size="large" />
      </div>

      <div className={styles.loader}>{submitting && <Loader isAbsolute={true} />}</div>

      <Modal
        isOpen={errorOpen}
        setClose={() => setErrorOpen(false)}
        contentClassName={styles.errorModal}
      >
        <div className={styles.row}>
          <CrossCircle className={styles.icon} />
          <p>No access</p>
        </div>
        <h1>It looks like you don’t have access yet.</h1>
        <p>Please contact your marketing manager to request access.</p>

        <div className={styles.buttonWrapper}>
          <Button appearance="secondary" onClick={() => setErrorOpen(false)}>
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};
