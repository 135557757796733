const appEnv = process.env.REACT_APP_ENV || "local";

interface Environment {
  apiUrl: string;
  smartlookKey: string;
  googleClientId: string;
  devSignInEmail: string;
  nodeEnv: "development" | "production" | "test";
}

interface Environments {
  [key: string]: Environment;
}

const environments: Environments = {
  local: {
    apiUrl: process.env.REACT_APP_API || "http://0.0.0.0:3000",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    devSignInEmail: process.env.REACT_APP_DEV_EMAIL as string,
    nodeEnv: process.env.NODE_ENV || "",
  },
  development: {
    apiUrl: process.env.REACT_APP_API || "https://in-dev.api.traffic.mergex.dev",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    devSignInEmail: process.env.REACT_APP_DEV_EMAIL as string,
    nodeEnv: process.env.NODE_ENV || "",
  },
  staging: {
    apiUrl: process.env.REACT_APP_API || "https://traffic.mergex.dev",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    devSignInEmail: process.env.REACT_APP_DEV_EMAIL as string,
    nodeEnv: process.env.NODE_ENV || "",
  },
  production: {
    apiUrl: process.env.REACT_APP_API as string,
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY as string,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    devSignInEmail: process.env.REACT_APP_DEV_EMAIL as string,
    nodeEnv: process.env.NODE_ENV || "",
  },
};

export const appEnvironment: Environment = environments[appEnv];
