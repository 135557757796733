import { FC, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import { Table, useFetch } from "@epcnetwork/core-ui-kit";

import { setListElement } from "utils";
import { ColumnsType } from "types";
import { ArrayElement, WhitelistUserModel } from "models";
import { getWhitelistUsers } from "api";
import { WhitelistTableRow } from "./users-list-table-row/whitelist-table-row";
import { UpdateWhitelistUser } from "./update/update-whitelist-user";
import { CreateWhitelistUser } from "./create/create-whitelist-user";

type ListUsersProps = {
  searchValue: string;
  createOpen: boolean;
  setCreateOpen: (value: boolean) => void;
};

const tableColumns: ColumnsType = [
  { label: "Email", required: true },
  { label: "Roles" },
  { label: "Group" },
  { label: "Status" },
  { label: "Created at" },
  { label: "" },
];

export const Whitelist: FC<ListUsersProps> = ({ searchValue, createOpen, setCreateOpen }) => {
  const [itemToEdit, setItemToEdit] = useState<WhitelistUserModel | null>(null);

  const { payload, loading, refresh, error, actions } = useFetch(getWhitelistUsers);

  const handleEdit = (item: WhitelistUserModel) => {
    setItemToEdit(item);
  };

  const onModifySuccess = (value: WhitelistUserModel) => {
    const list = payload || [];
    const newData = setListElement(list, value);

    actions.setData(newData);
  };

  const filteredList = useMemo(() => {
    let list: NonNullable<typeof payload> = payload || [];

    if (searchValue) {
      const keys: (keyof ArrayElement<NonNullable<typeof payload>>)[] = ["email"];
      list = matchSorter(list, searchValue, { keys });
    }

    return list;
  }, [searchValue, payload]);

  return (
    <>
      <Table
        entityName="whitelist"
        columns={tableColumns}
        list={filteredList}
        error={error?.message}
        refresh={refresh}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        row={(item, index) => (
          <WhitelistTableRow index={index} item={item} handleEdit={handleEdit} />
        )}
      />

      <CreateWhitelistUser
        isOpen={createOpen}
        handleClose={() => setCreateOpen(false)}
        onCreateSuccess={onModifySuccess}
      />

      {itemToEdit && (
        <UpdateWhitelistUser
          item={itemToEdit}
          isOpen={!!itemToEdit}
          handleClose={() => setItemToEdit(null)}
          onUpdateSuccess={onModifySuccess}
        />
      )}
    </>
  );
};
