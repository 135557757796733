import { useSelector } from "react-redux";
import { FC } from "react";

import { RootState } from "store";
import { MfaForm } from "./mfa-form/mfa-form";
import { LoginSidebar } from "./login-sidebar/login-sidebar";
import { GoogleLogin } from "./google-login/google-login";

import trafficLink from "assets/images/traffic-link.svg";
import styles from "./login.module.css";

const LoginPage: FC = () => {
  const { mfaTemporaryToken } = useSelector((state: RootState) => state.auth);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img className={styles.icon} src={trafficLink} alt="" />
        <div className={styles.logoText}>Traffico.ai</div>
      </div>
      <LoginSidebar />
      <div className={styles.loginForm}>
        <div className={styles.formTitle}>Welcome to Traffico.ai</div>
        <div className={styles.formSubTitle}>Please use your Google account to sign in</div>

        {!mfaTemporaryToken && <GoogleLogin />}
        {!!mfaTemporaryToken && <MfaForm temporaryToken={mfaTemporaryToken} />}
      </div>
    </div>
  );
};

export { LoginPage };
