import { AnyObjectSchema, array, number, object, string } from "yup";

import { UserModel } from "models";
import { requiredFieldText } from "constants/form.constants";
import { UserFormData } from "api/users/users.interface";

export const validationSchema: AnyObjectSchema = object().shape({
  email: string().email("Email is not valid").required("Email is required"),
  roleId: number().required("Role is required").typeError("Role is invalid"),
  groupIds: array(number().required(requiredFieldText))
    .min(1, requiredFieldText)
    .typeError(requiredFieldText),
});

export const initialData = (item: UserModel): UserFormData => ({
  email: item?.email || "",
  roleId: item?.roleId,
  groupIds: item?.groups.map(({ id }) => id) || [],
});
